import { Datagrid, List, TextField } from "react-admin";
import { StatisticsByStatus } from "src/common/statisticsByStatus";
import DuplicateField from "src/components/DuplicateField";

const JournalPageList = () => (
  <List aside={<StatisticsByStatus />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="title" />
      <TextField source="status" />
      <DuplicateField resource="journals-pages"/>
    </Datagrid>
  </List>
);

export default JournalPageList;
