import { Box } from "@mui/material";
import { Create, TabbedForm } from "react-admin";

import { useMutationErrorNotify } from "../common/handleMutationError";
import { JournalPageOverview } from "./subforms/journalPageOverview";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";

const JournalPageCreate = () => {
  const notify = useMutationErrorNotify();

  return (
    <Create
      title="Journal Page"
      transform={transformDataBeforeRequest}
      mutationOptions={{
        onError: notify
      }}
    >
      <TabbedForm>
        <TabbedForm.Tab label="Overview">
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", xl: 1414 }}
            paddingBottom="100px"
            gap="3%"
          >
            <JournalPageOverview />
          </Box>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default JournalPageCreate;
