import { Box } from "@mui/material";
import { FC } from "react";
import {
  ArrayInput,
  DateTimeInput,
  maxLength,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Validator,
} from "react-admin";
import { Language } from "src/activities/types";
import { LanguageChoices } from "src/practitioners/languageChoices";

import { PlannedMaintenanceTypeChoices } from "./choices";
import { PlannedMaintenanceMessage } from "./types";

const messagesValidator: Validator = (
  value: Array<PlannedMaintenanceMessage>
) => {
  if (!value.some(({ language }) => language === Language.de)) {
    return "Message in German is required";
  }

  return undefined;
};

export const PlannedMaintenanceForm: FC = () => (
  <SimpleForm>
    <Box>
      <DateTimeInput
        source="startDate"
        validate={[required()]}
        fullWidth
        helperText="From this time message will be shown to the users"
      />
      <DateTimeInput
        source="endDate"
        validate={[required()]}
        fullWidth
        helperText="After this time message will no longer be visible to the users"
      />
      <SelectInput
        source="type"
        choices={PlannedMaintenanceTypeChoices}
        validate={[required()]}
        fullWidth
      />
      <ArrayInput
        source="messages"
        validate={[required(), messagesValidator]}
        fullWidth
      >
        <SimpleFormIterator fullWidth inline>
          <SelectInput
            source="language"
            choices={LanguageChoices}
            validate={[required()]}
            sx={{ flex: 1 }}
          />
          <TextInput
            source="message"
            validate={[required(), maxLength(500)]}
            multiline
            minRows={5}
            sx={{ flex: 3 }}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  </SimpleForm>
);
