import "./style.css";

import AddIcon from "@mui/icons-material/AddCircleOutline";
import ArrowDownwardIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowUpwardIcon from "@mui/icons-material/ArrowCircleUp";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { useCallback } from "react";
import { useSimpleFormIterator, useSimpleFormIteratorItem } from "react-admin";
import { Path, PathValue, useFormContext } from "react-hook-form";
import { removeIds } from "src/utils/helpers";

interface FormItemButtonsProps<T> {
  defaultValue: Partial<T>;
  allowDuplication: boolean;
}

export const FormItemButtons = <T, K>({
  defaultValue,
  allowDuplication,
}: FormItemButtonsProps<T>) => {
  const { index, total, reOrder } = useSimpleFormIteratorItem();
  const { source } = useSimpleFormIterator();
  const { watch, setValue } = useFormContext<K>();

  const value = watch(source as Path<K>) as Array<Partial<T>>;

  const blink = useCallback(() => {
    setTimeout(() => {
      const addedListItem =
        document.getElementsByClassName("items-list")[0]?.children[0]?.children[
          index + 1
        ];

      addedListItem?.classList.add("blink");
      addedListItem?.scrollIntoView();

      setTimeout(() => {
        addedListItem?.classList.remove("blink");
      }, 3000);
    }, 50);
  }, [index]);

  const addOneBelow = useCallback(() => {
    if (Array.isArray(value)) {
      const newValue = [...value];
      newValue.splice(index + 1, 0, defaultValue);
      setValue(source as Path<K>, newValue as PathValue<K, Path<K>>);

      blink();
    }
  }, [blink, defaultValue, index, setValue, source, value]);

  const copyOneBelow = useCallback(() => {
    if (Array.isArray(value)) {
      const newValue = [...value];
      const valueToCopy = removeIds<Partial<T>>(
        value.find((_, i) => i === index) ?? defaultValue
      );
      newValue.splice(index + 1, 0, valueToCopy);
      setValue(source as Path<K>, newValue as PathValue<K, Path<K>>);

      blink();
    }
  }, [blink, defaultValue, index, setValue, source, value]);

  return (
    <div>
      <div className="row">
        <Tooltip title="Add one below">
          <span>
            <IconButton
              size="small"
              onClick={addOneBelow}
              disabled={total == null || index >= total - 1}
              color="primary"
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
        {allowDuplication && (
          <Tooltip title="Duplicate">
            <span>
              <IconButton size="small" onClick={copyOneBelow} color="secondary">
                <ContentCopyOutlined />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>
      <div className="row">
        <Tooltip title="Move up">
          <span>
            <IconButton
              size="small"
              onClick={() => reOrder(index - 1)}
              disabled={index <= 0}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Move down">
          <span>
            <IconButton
              size="small"
              onClick={() => reOrder(index + 1)}
              disabled={total == null || index >= total - 1}
            >
              <ArrowDownwardIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};
