import { Datagrid, EditButton, List, ShowButton, TextField } from "react-admin";
import { StatisticsByStatus } from "src/common/statisticsByStatus";
import DuplicateField from "src/components/DuplicateField";

const ActivityList = () => {
  return (
    <List aside={<StatisticsByStatus />}>
      <Datagrid rowClick={null} bulkActionButtons={false}>
        <TextField source="title" />
        <TextField source="activityType" />
        <TextField source="status" />
        <EditButton label="Edit"/>
        <ShowButton label="Statistics" />
        <DuplicateField resource="activities"/>
      </Datagrid>
    </List>
  );
}

export default ActivityList;
