import { Language } from "src/activities/types";

export interface PlannedMaintenance {
  id: string;
  startDate: string;
  endDate: string;
  messages: Array<PlannedMaintenanceMessage>;
  type: PlannedMaintenanceType;
}

export enum PlannedMaintenanceType {
  INFO = "INFO",
  WARNING = "WARNING",
}

export interface PlannedMaintenanceMessage {
  language: Language;
  message: string;
}
