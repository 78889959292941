import { Picture } from "./types";

/**
 * Recursively removes the `_id` property from an object or array.
 * This function takes any object or array and returns a new object or array with all `_id` properties removed.
 * `_id` is removed from all nested levels.
 * 
 * @template T The type of the input object or array.
 * @param {T} obj The object or array to process.
 * @returns {T} A new object or array with all `_id` properties removed.
 */
export const removeIds = <T>(obj: T, resourceName?: string): T => {
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map((item) => removeIds(item, resourceName)) as T;
  } else if (obj && typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, [key, value]) => {

      if (key === 'id' || key === '_id') {
        return acc;
      }

      (acc as Record<string, unknown>)[key] = removeIds(value, resourceName);
      return acc;
    }, {} as T);
  }

  return obj;
};

export const transformPictureForSave = (
  picture: Picture,
  isDuplicateMode: boolean
): Picture | undefined => {
  if (isDuplicateMode) {
    return {
      ...picture,
      src: picture.src.split('/').pop() || "",
    };
  }
  
  return undefined;
}