import { FC } from "react";
import {
  Create,
} from "react-admin";
import { useMutationErrorNotify } from "src/common/handleMutationError";

import { PlannedMaintenanceForm } from "./PlannedMaintenanceForm";
import { PlannedMaintenance } from "./types";

const PlannedMaintenancesCreate: FC = (props) => {
  const notify = useMutationErrorNotify();

  return (
    <Create<PlannedMaintenance>
      title="Planned Maintenance"
      mutationOptions={{
        onError: notify,
      }}
      {...props}
    >
      <PlannedMaintenanceForm />
    </Create>
  );
};

export default PlannedMaintenancesCreate;
