import { FC } from "react";
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  List,
  SelectField,
} from "react-admin";

import { PlannedMaintenanceTypeChoices } from "./choices";
import { PlannedMaintenance } from "./types";

const PlannedMaintenancesList: FC = (props) => (
  <List<PlannedMaintenance>
    sort={{ field: "startDate", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick={null} bulkActionButtons={false}>
      <DateField source="startDate" showTime />
      <DateField source="endDate" showTime />
      <SelectField
        source="type"
        choices={PlannedMaintenanceTypeChoices}
        sortable={false}
      />
      <EditButton label="Edit" />
      <DeleteButton label="Delete" mutationMode="pessimistic"/>
    </Datagrid>
  </List>
);

export default PlannedMaintenancesList;
