import { FC, useMemo } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  minValue,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { useFormState } from "react-hook-form";
import { commonItemsStyling } from "src/activities/commonStyling";
import { CustomNumberInput } from "src/components/CustomNumberInput";
import { FormItemButtons } from "src/components/FormItemButtons";
import { LanguageChoices } from "src/practitioners/languageChoices";

import { PolicyRole, PolicyStatus, PolicyType } from "./choices";
import { Policy, PolicyStatus as Status } from "./interfaces";

interface PolicyFormProps {
  isEditing?: boolean;
}

const PolicyForm: FC<PolicyFormProps> = ({ isEditing = false }) => {
  const {
    defaultValues: { status },
  } = useFormState<Policy>();

  const policyStatusChoices = useMemo(() => {
    if (!isEditing || status === Status.DRAFT) {
      return PolicyStatus;
    }

    if (isEditing && status === Status.ACTIVE) {
      return PolicyStatus.filter((status) => status.id !== Status.DRAFT);
    }

    return PolicyStatus.filter((status) => status.id === Status.RETIRED);
  }, [isEditing, status]);

  return (
    <>
      <ArrayInput source="translations" validate={required()}>
        <SimpleFormIterator
          sx={commonItemsStyling}
          inline
          getItemLabel={(index) => `#${index + 1}`}
          reOrderButtons={
            <FormItemButtons defaultValue={{}} allowDuplication={false} />
          }
          className="items-list"
        >
          <SelectInput
            source="locale"
            choices={LanguageChoices}
            fullWidth
            disabled={isEditing && status === Status.RETIRED}
          />
          <TextInput
            source="title"
            validate={required()}
            fullWidth
            disabled={isEditing && status === Status.RETIRED}
          />
          <TextInput
            source="text"
            validate={required()}
            fullWidth
            multiline
            disabled={isEditing && status === Status.RETIRED}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput
        source="type"
        choices={PolicyType}
        validate={required()}
        disabled={isEditing}
      />
      <CustomNumberInput
        source="version"
        validate={[required(), minValue(1)]}
        defaultValue={1}
        fullWidth
        disabled={isEditing}
      />
      <SelectInput
        source="status"
        choices={policyStatusChoices}
        validate={required()}
        defaultValue={"DRAFT"}
      />
      <SelectInput
        source="role"
        choices={PolicyRole}
        validate={required()}
        defaultValue={"COMMON"}
        disabled={isEditing}
      />
      <BooleanInput
        source="isOptional"
        defaultValue={false}
        disabled={isEditing && status !== Status.DRAFT}
        helperText="If selected, user can access the application without consenting to the policy"
      />
      <ReferenceInput
        label="Applies to institution"
        source="institutionId"
        reference="institutions"
        disabled={isEditing}
      >
        <AutocompleteInput
          fullWidth
          disabled={isEditing}
          helperText="Fill in for institution specific policies"
        />
      </ReferenceInput>
    </>
  );
};

export default PolicyForm;
