import { FC } from "react";
import { Edit } from "react-admin";
import { useMutationErrorNotify } from "src/common/handleMutationError";

import { PlannedMaintenanceForm } from "./PlannedMaintenanceForm";
import { transformDataBeforeRequest } from "./transformDataBeforeRequest";
import { PlannedMaintenance } from "./types";

const PlannedMaintenancesEdit: FC = (props) => {
  const notify = useMutationErrorNotify();

  return (
    <Edit<PlannedMaintenance>
      title="Planned Maintenance"
      mutationOptions={{
        onError: notify,
      }}
      mutationMode="pessimistic"
      transform={transformDataBeforeRequest}
      {...props}
    >
      <PlannedMaintenanceForm />
    </Edit>
  );
};

export default PlannedMaintenancesEdit;
