import { useRecordContext } from 'react-admin';
import { ResourceData } from 'src/common/types';

import DuplicateButton from '../DuplicateButton';

interface DuplicateFieldProps {
  resource: keyof ResourceData;
}

const DuplicateField = ({ resource }: DuplicateFieldProps) => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    return <DuplicateButton resource={resource} />;
};

DuplicateField.defaultProps = {
    label: 'Duplicate',
    sortable: false,
};

export default DuplicateField;