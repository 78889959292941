import { FormHelperText } from "@mui/material";
import { FC, useCallback } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
  Validator,
} from "react-admin";
import { useWatch } from "react-hook-form";
import { commonItemsStyling } from "src/activities/commonStyling";
import { Language, PublicationStatus } from "src/activities/types";
import { CustomNumberInput } from "src/components/CustomNumberInput";
import { FormItemButtons } from "src/components/FormItemButtons";

import { Pathway, PathwayActivity, PathwayTimeRelation } from "../interfaces";

interface PathFormProps {
  source: string;
}

export const PathForm: FC<PathFormProps> = ({ source }) => {
  const timeRelation = useWatch<Pathway>({
    name: "mainPath.timeRelation",
  }) as PathwayTimeRelation | undefined;

  const activities = useWatch<Pathway>({
    name: source as unknown as keyof Pathway,
  }) as Array<PathwayActivity> | undefined;

  const status = useWatch<Pathway>({
    name: "status" as unknown as keyof Pathway,
  }) as PublicationStatus;

  const language = useWatch<Pathway>({
    name: "language" as unknown as keyof Pathway,
  }) as Language;

  const validate: Validator = useCallback(() => {
    if (!activities?.length) {
      return { message: "Required", args: [] };
    }

    return undefined;
  }, [activities]);

  return (
    <>
      <ArrayInput
        source={source}
        disabled={!timeRelation}
        required
        validate={validate}
      >
        <SimpleFormIterator
          sx={commonItemsStyling}
          inline
          getItemLabel={(index) => `#${index + 1}`}
          reOrderButtons={
            <FormItemButtons
              defaultValue={{
                activityId: "",
              }}
              allowDuplication={false}
            />
          }
          className="items-list"
        >
          <FormHelperText>
            Activity list depends on Pathway&apos;s Publication Status and
            Language:
          </FormHelperText>
          <ReferenceInput
            label="Activity"
            source="activityId"
            reference="activities"
            validate={required()}
            filter={{
              status:
                status !== PublicationStatus.testing
                  ? PublicationStatus.active
                  : {
                      $in: [
                        PublicationStatus.testing,
                        PublicationStatus.active,
                      ],
                    },
              language: language ?? null,
            }}
          >
            <AutocompleteInput fullWidth validate={required()} />
          </ReferenceInput>
          <CustomNumberInput
            source={"offsetInDays"}
            validate={required()}
            label={
              (timeRelation === PathwayTimeRelation.RELATIVE &&
                "Starts X days after previous") ||
              "Starts at day"
            }
            min={0}
            sx={{ flex: 2 }}
            helperText={
              (timeRelation === PathwayTimeRelation.RELATIVE &&
                "If set to 0, activity will start immediately after previous one is completed") ||
              "If set to 0, activity will start immediately after pathway is assigned"
            }
          />
          <BooleanInput
            source={"shouldEndOnNextStart"}
            defaultValue={false}
            sx={{
              flex: 1,
              visibility:
                timeRelation === PathwayTimeRelation.RELATIVE
                  ? "hidden"
                  : "visible",
            }}
            helperText="If set to TRUE, activity will become missed at start of the next one"
            disabled={timeRelation === PathwayTimeRelation.RELATIVE}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
