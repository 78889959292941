import { KeycloakInitOptions } from "keycloak-js";
import { useEffect, useState } from "react";
import { Admin, CustomRoutes, Resource, useNotify } from "react-admin";
import { Route } from "react-router-dom";

import activities from "./activities";
import auditTrails from "./auditTrails";
import { authProvider, keycloakClient } from "./auth";
import { RedirectToInstitutions } from "./common/redirectToInstitutions";
import { AppRoleContextProvider } from "./context/AppRoleContext/AppRoleContextProvider";
import Dashboard from "./dashboard/dashboard";
import { dataProvider } from "./dataProvider";
import deviceIdentifiers from "./device-identifiers";
import institutions from "./institutions";
import journals from "./journals";
import journalsPages from "./journalsPages";
import Layout from "./Layout";
import onboardings from "./onboardings";
import pathways from "./pathways";
import patients from "./patients";
import plannedMaintenances from "./plannedMaintenances";
import policies from "./policies";
import practitioners from "./practitioners";
import requests from "./removeAccountRequests";

const initOptions: KeycloakInitOptions = {
  onLoad: "login-required",
  pkceMethod: "S256",
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const notify = useNotify();

  useEffect(() => {
    const initKeyCloakClient = async () => {
      await keycloakClient.init(initOptions).catch((error) => {
        notify(<>{JSON.stringify(error)}</>, { type: "error" });
      });
      setIsLoading(false);
    };

    void initKeyCloakClient();
  }, [notify]);

  if (isLoading) return <div>loading</div>;

  return (
    <AppRoleContextProvider>
      <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        title="YLAH Admin panel"
        layout={Layout}
        dashboard={Dashboard}
      >
        {(permissions) => (
          <>
            {permissions ? (
              <>
                {institutions}
                <Resource name="activities" {...activities} />
                <Resource name="pathways" {...pathways} />
                <Resource name="journals-pages" {...journalsPages} />
                <Resource name="journals" {...journals} />
                <Resource name="policies" {...policies} />
                <Resource name="users/practitioners" {...practitioners} />
                <Resource name="users/patients" {...patients} />
                <Resource name="onboardings" {...onboardings} />
                <Resource name="users/remove-requests" {...requests} />
                <Resource name="device-identifiers" {...deviceIdentifiers} />
                <Resource name="planned-maintenances" {...plannedMaintenances} />
                <Resource name="audit-trails" {...auditTrails} />
                <CustomRoutes>
                  <Route
                    path="/users/practitioners/:practitionerId/institutions/:institutionId"
                    element={<RedirectToInstitutions tabToRedirectIndex={2} />}
                  />
                  <Route
                    path="/users/patients/:patientId/institutions/:institutionId"
                    element={<RedirectToInstitutions tabToRedirectIndex={3} />}
                  />
                </CustomRoutes>
              </>
            ) : null}
          </>
        )}
      </Admin>
    </AppRoleContextProvider>
  );
};
export default App;
