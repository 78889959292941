import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from 'react';
import { Button,useDataProvider, useNotify, useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { ResourceData } from 'src/common/types';
import { removeIds } from 'src/utils/helpers';

interface DuplicateButtonProps {
  resource: keyof ResourceData;
}


const DuplicateButton = ({ resource }: DuplicateButtonProps) => {
  const record = useRecordContext<ResourceData[typeof resource]>();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const sanitizeData = (data: ResourceData[typeof resource]): ResourceData[typeof resource] => {
    if (resource === 'journals') {
      const modifiedData = { ...data } as ResourceData['journals'];
  
      delete modifiedData.id;
  
      if (modifiedData.introduction) {
        modifiedData.introduction = removeIds(modifiedData.introduction, resource);
      }
  
      return modifiedData;
    }
  
    return removeIds(data, resource);
  };

  const handleClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setLoading(true);

    try {
      const { data } = await dataProvider.getOne<ResourceData[typeof resource]>(resource, { id: record.id });

      
      const sanitizedData = sanitizeData(data);

      navigate(`/${resource}/create`, { state: { record: sanitizedData } });
    } catch (error) {
      notify('Error fetching record data', { type: 'warning' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button onClick={(event) => { void handleClick(event); }} label="Duplicate" disabled={loading} startIcon={<ContentCopyIcon />} />
  );
};

export default DuplicateButton;