import { PlannedMaintenanceType } from "./types";

export const PlannedMaintenanceTypeChoices: Array<{
  id: PlannedMaintenanceType;
  name: string;
}> = [
  {
    id: PlannedMaintenanceType.INFO,
    name: "Info",
  },
  {
    id: PlannedMaintenanceType.WARNING,
    name: "Warning",
  },
];
