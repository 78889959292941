import { FC } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleFormIterator,
  Validator,
} from "react-admin";
import { commonItemsStyling } from "src/activities/commonStyling";
import { FormItemButtons } from "src/components/FormItemButtons";
import { GroupedSelectInput } from "src/components/GroupSelectInput";
import { JournalPage } from "src/journalsPages/types";

import { AllDayTimePeriod, JournalTimePeriod } from "../choices";
import { Journal } from "../types";

const AllDayValues = AllDayTimePeriod.map(({ id }) => id);
const MultipleEntriesValues = JournalTimePeriod.map(({ id }) => id);

const journalPagesValidator: Validator = (value: string, values: Journal) => {
  const isAllDay = AllDayValues.includes(value);
  const isMultipleEntries = MultipleEntriesValues.includes(value);

  const hasJournalAllDayPages = values.pages.some(({ timePeriod }) =>
    AllDayValues.includes(timePeriod)
  );
  const hasJournalMultipleEntriesPages = values.pages.some(({ timePeriod }) =>
    MultipleEntriesValues.includes(timePeriod)
  );

  if (isAllDay && hasJournalMultipleEntriesPages) {
    return 'Cannot set "ALL_DAY" when journal has different time periods';
  }

  if (isMultipleEntries && hasJournalAllDayPages) {
    return 'Cannot set a different period when journal has "ALL_DAY" periods configured';
  }

  return null;
};

export const JournalPages: FC = () => {
  return (
    <>
      <ArrayInput
        source="pages"
        required
        validate={(values: JournalPage[]) =>
          values && values.length ? undefined : "At least one page is required."
        }
      >
        <SimpleFormIterator
          sx={commonItemsStyling}
          inline
          getItemLabel={(index) => `#${index + 1}`}
          reOrderButtons={
            <FormItemButtons
              defaultValue={{ id: "", timePeriod: "" }}
              allowDuplication={false}
            />
          }
          className="items-list"
        >
          <ReferenceInput
            label="Page"
            source="id"
            reference="journals-pages"
            validate={required()}
            fullWidth
          >
            <AutocompleteInput fullWidth validate={required()} />
          </ReferenceInput>
          <GroupedSelectInput
            source="timePeriod"
            validate={[required(), journalPagesValidator]}
            label="Time Period"
            choices={[
              {
                group: "All day journal",
                items: AllDayTimePeriod,
              },
              {
                group: "Multiple entries journal",
                items: JournalTimePeriod,
              },
            ]}
            fullWidth
          />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
