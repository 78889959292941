import {
  CreateParams,
  CreateResult,
  Identifier,
  ResourceCallbacks,
  UpdateParams,
  UpdateResult
} from "react-admin";
import { getHeadersForCustomDataProviderMethod } from "src/auth";
import { transformPictureForSave } from "src/utils/helpers";
import { Picture } from "src/utils/types";

import { Activity } from "./types";

const activityPictures = new Map<
  Identifier,
  {
    picture: Picture;
    isDuplicate: boolean;
  }
>();

function handleBeforeSave<
  T extends
    | CreateParams<Activity & { id: string }>
    | UpdateParams<Activity & { id: string }>
>(params: T): Promise<T> {
  // TODO: isDuplicateMode shouldnt be necessary after YLH-2065
  const isDuplicateMode = !("id" in params) && params.data.picture?.src;

  if (params.data.picture) {
    const pictureWithMeta = {
      picture: params.data.picture,
      isDuplicate: Boolean(isDuplicateMode),
    };

    activityPictures.set("id" in params ? params.id : "", pictureWithMeta);

    return Promise.resolve({
      ...params,
      data: {
        ...params.data,
        picture: transformPictureForSave(params.data.picture, pictureWithMeta.isDuplicate)
      }
    });
  }

  return Promise.resolve(params);
}

async function handleAfterSave<
  T extends
    | CreateResult<Activity & { id: string }>
    | UpdateResult<Activity & { id: string }>
>(result: T, initialId?: string): Promise<T> {
  const key = typeof initialId === "string" ? initialId : result.data.id;

  if (activityPictures.has(key)) {
    const { picture, isDuplicate } = activityPictures.get(key) || {};

    if (!picture || !("rawFile" in picture)) {
      activityPictures.delete(key);

      return result;
    }

    const body = new FormData();
    body.append("picture", picture.rawFile);
    body.append("isDuplicate", isDuplicate.toString());

    const headers = getHeadersForCustomDataProviderMethod();

    headers.delete("Content-Type");
    headers.append("enctype", "multipart/form-data");

    const response = await fetch(
      `/api/superuser/activities/${result.data.id}/picture`,
      {
        method: "PUT",
        headers,
        body
      }
    );

    if (!response.ok) {
      throw new Error("Activity data saved, but image upload has failed");
    }

    const { picture: updatedPicture } = (await response.json()) as {
      picture: { src: string; weight: number; height: number };
    };

    activityPictures.delete(key);

    return {
      ...result,
      data: { ...result.data, picture: updatedPicture },
    };
  } else if (result.data.picture) {
    const headers = getHeadersForCustomDataProviderMethod();
    const response = await fetch(
      `/api/superuser/activities/${result.data.id}/picture`,
      {
        method: "DELETE",
        headers
      }
    );

    if (!response.ok) {
      throw new Error("Activity data saved, but image removal has failed");
    }

    return { ...result, data: { ...result.data, picture: undefined } };
  }

  return result;
}

export const activityLifecycleCallbacks: ResourceCallbacks<
  Activity & { id: string }
> = {
  resource: "activities",
  beforeCreate(params) {
    return handleBeforeSave(params);
  },
  afterCreate(result) {
    return handleAfterSave(result, "");
  },
  beforeUpdate(params) {
    return handleBeforeSave(params);
  },
  afterUpdate(result) {
    return handleAfterSave(result);
  }
};
