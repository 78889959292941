import Construction from "@mui/icons-material/Construction";

import { PlannedMaintenanceTypeChoices } from "./choices";
import PlannedMaintenancesCreate from "./create";
import PlannedMaintenancesEdit from "./edit";
import PlannedMaintenancesList from "./list";
import { PlannedMaintenance } from "./types";

export default {
  icon: Construction,
  recordRepresentation: (record: PlannedMaintenance) =>
    `${
      PlannedMaintenanceTypeChoices.find(({ id }) => id === record.type)?.name
    } - ${new Date(record.startDate).toLocaleDateString()}`,
  options: {
    label: "Planned Maintenances",
  },
  list: PlannedMaintenancesList,
  create: PlannedMaintenancesCreate,
  edit: PlannedMaintenancesEdit,
};
